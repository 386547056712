(function() {
  const textSel = document.querySelector('.js-toc');

  if (!textSel) return;

  const convertRemToPixels = (rem) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  };
  const tocHeaders = textSel.querySelectorAll('[data-scroll]');
  const headerEl = document.querySelector('.js-header');

  // so it's aligned with toc
  const spacingRootVar = getComputedStyle(document.documentElement).getPropertyValue('--space-s');
  const spacingRemValue = spacingRootVar.replace('rem', '');
  const topSpacingValue = convertRemToPixels(spacingRemValue);

  tocHeaders.forEach((header) => {
    // remove any link element plaintext from headers
    const stripLink = header.innerText.replace(/<a\b[^>]*>/gm, '').replace(/<\/a>/gm, '');
    header.innerText = stripLink;

    header.addEventListener('click', (e) => {
      e.preventDefault();

      const href = e.currentTarget.getAttribute('href');
      const offsetTop = document.getElementById(href.substring(1)).offsetTop - headerEl.offsetHeight - topSpacingValue;

      scroll({
        top: offsetTop,
        behavior: 'smooth',
      });
    });
  });
})();
